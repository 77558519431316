<template>
  <inertia-link
    :href="href"
    class="duration-50 flex w-24 items-center justify-center transition ease-in-out hover:bg-brand-green hover:text-brand-gray"
    :class="height"
  >
    <slot>
      <arrow-left-icon class="h-12 w-12" />
    </slot>
  </inertia-link>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
  name: "NavBarLink",
  components: {
    "inertia-link": Link,
  },
  props: {
    href: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      default: "h-24",
    },
  },
};
</script>
