<template>
  <Link
    :href="href"
    :disabled="disabled"
    class="focus:shadow-outline-gray mb-4 inline-flex items-center border border-brand-blue-dark bg-brand-blue-dark px-6 py-4 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-brand-green hover:text-brand-blue-dark focus:bg-brand-green focus:outline-none active:bg-brand-green"
  >
    <slot />
  </Link>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
  name: "DefaultButton",
  components: {
    Link,
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
