import "../css/app.css";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";

import { ArrowLeftIcon } from "@heroicons/vue/solid";
import BrandLink from "@/Components/Shared/DefaultButton.vue";
import NavBarLink from "@/Components/NavigationBar/Link.vue";

import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import axios from "axios";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "PlanMij";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, app, props, plugin }) {
    const vueApp = createApp({ render: () => h(app, props) })
      .use(plugin)
      .mixin({ methods: { route } })
      .mixin({
        components: {
          "arrow-left-icon": ArrowLeftIcon,
          "brand-link": BrandLink,
          "nav-bar-link": NavBarLink,
        },
      });

    if (window.bugsnagConfig.enabled) {
      Bugsnag.start({
        apiKey: window.bugsnagConfig.apiKey,
        appVersion: window.bugsnagConfig.appVersion,
        plugins: [new BugsnagPluginVue()],
        releaseStage: window.bugsnagConfig.releaseStage,
      });

      const bugsnagVue = Bugsnag.getPlugin("vue");

      vueApp.use(bugsnagVue);
    }

    return vueApp.mount(el);
  },
});

InertiaProgress.init({ color: "#4B5563" });
